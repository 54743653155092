<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <!-- DEBUG -->
  <!-- <div class="absolute -top-10 text-white"
    :style="{ left: props.x+'px', top: props.y-20+'px'}"
  >
    {{ popoverHover }}
  </div> -->
  <Popover v-slot="{ open, close }" class="plate"
      :style="{ left: props.x+'px', top: props.y+'px', width: props.width+'px', height: props.height+'px' }"
  >
    <PopoverButton class="w-full" ref="popoverButton"
      @mouseover="(e) => hoverPopover(e, open)" @mouseleave="closePopover(close)"
    >
      <div class="absolute top-0 bottom-0 left-0 right-0 glow"/>
      <slot name="image">
        <img class="absolute top-0 left-0" src="@/assets/img/ecommerce-storyline/plate.webp">
      </slot>
    </PopoverButton>

    <transition 
      enter-active-class="transition-opacity duration-200 ease-out" 
      enter-from-class="opacity-0"
      enter-to-class="opacity-100" 
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="opacity-100" 
      leave-to-class="opacity-0">
      <PopoverPanel class="absolute z-10 transform bg-white" style="width: 300px;"
        :style="panelStyle"
        :class="panelClasses"
        @mouseover.prevent="popoverHover = true" @mouseleave.prevent="closePopover(close)">
        <div class="shadow-lg ring-1 ring-black ring-opacity-5 p-6">
          <h2 class="text-2xl mb-2">{{ title }}</h2>
          <slot />
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import { ref } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

export enum Placement {
  top = "top",
  right = "right",
  bottom = "bottom",
}

export interface Props {
  x?: number,
  y?: number,
  width?: number,
  height?: number,
  title: string,
  placement?: Placement,
  panelStyle?: string,
}</script>
<script setup lang="ts">
const props = withDefaults(defineProps<Props>(), {
  x: 0,
  y: 295,
  width: 55,
  height: 28,
  title: '',
  placement: Placement.top,
  panelStyle: '',
})

const popoverHover = ref(false)
const popoverButton = ref<typeof PopoverButton|null>(null)
const popoverTimeout = ref(null)

const hoverPopover = (e, open) => {
  if(!smallScreen.value) {
    popoverHover.value = true
    if (!open) e.target.click()
  }
}

const closePopover = (close) => {
  if(!smallScreen.value) {
    popoverHover.value = false
    if (popoverTimeout.value) clearTimeout(popoverHover.value)
    popoverTimeout.value = setTimeout(() => {
      if (!popoverHover.value) close()
    }, 100)
  }
}

const panelClasses = computed( ()=> {
  switch(props.placement) {
    case Placement.top:
      return 'right-0 -translate-y-full -top-full'
    case Placement.right:
      return 'translate-x-full -right-4 -translate-y-1/2' 
    case Placement.bottom:
      return 'translate-y-full -bottom-4'
  }
  return ''
})

const smallScreen = computed( () => {
  if(import.meta.client) {
    return window.innerWidth <= 768
  }
  return true
})
onMounted( ()=> {
})
</script>

<style>
/* TODO: global classes!! */
  .plate {
    position: absolute;
  }
  .plate button {
    padding: 0;
    min-height: 0;
    cursor: help;
  }
  .plate img {
    width: 100%;
  }

  .glow {
  /* filter: blur(7px); */
  border-radius: 10%;
  animation: glow 2s infinite alternate;
}



/* Define the animation */
@keyframes glow {
  from {
    /* -moz-box-shadow: 0 0 0 1px rgba(0, 176, 190, 0);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 176, 190, 0); */
    box-shadow: 0 0 10px 0px rgba(0, 176, 190, 0); /* Transparent glow at start */
  }
  to {
    /* -moz-box-shadow: 0 0 0 3px rgba(0, 176, 190, 1);
    -webkit-box-shadow: 0 0 0 3px rgba(0, 176, 190, 1); */
    box-shadow: 0 0 10px 3px rgba(0, 176, 190, 0.5); /* Full opacity glow at end */
  }
}
</style>
